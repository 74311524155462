<template>
    <div id="app">
        <div class="container">
            <h2 class="border-bottom pb-2 mb-3 text-center">
                <i class="fa fa-yahoo" style="font-size: 48px; color: red"></i
                >Yahoo Search
            </h2>
            <div class="search-container">
                <div
                    class="widget-content widget-content-area component-card_1 mb-4"
                >
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-md-10">
                            <div class="control-styles dependency-section">
                                <h6>Filter</h6>
                                <div class="add-dependency">
                                    <input
                                        class="e-control e-textbox e-outline e-lib e-input e-keyboard"
                                        placeholder="Add Filter"
                                        v-model="newDependency"
                                        @keyup.enter="addDependency"
                                    />
                                </div>
                                <ul class="dependency-list">
                                    <li
                                        v-for="(
                                            dependency, index
                                        ) in dependencies"
                                        :key="index"
                                        class="dependency-item"
                                    >
                                        {{ dependency }}
                                        <button
                                            data-v-865826d0=""
                                            class="btn btn-danger"
                                            @click="removeDependency(index)"
                                            type="button"
                                        >
                                            <i
                                                data-v-865826d0=""
                                                title="Delete"
                                                class="fa fa-trash"
                                            ></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div class="tabs-wrap text-center mt-4">
                                <button
                                    class="e-control e-progress-btn e-lib e-btn e-spin-right"
                                    data-ripple="true"
                                    aria-label="SEARCH progress"
                                    style="
                                        color: white;
                                        background-color: rgb(56, 56, 56);
                                    "
                                    @click="filter"
                                >
                                    <span class="e-btn-content"
                                        ><i
                                            class="fa fa-search"
                                            aria-hidden="true"
                                        ></i
                                    ></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Contenedor para resultados de búsqueda -->
            <div class="results-container">
                <!-- Título de los resultados -->
                <h6 class="text-center">History</h6>
                <!-- Contenido de los resultados -->
                <div class="search-results">
                    <div v-if="paginatedResults.length > 0">
                        <div
                            v-for="(result, index) in paginatedResults"
                            :key="index"
                            class="result-item"
                        >
                            <!-- Fila para cada resultado -->
                            <div class="result-row">
                                <p class="result-date">{{ result.regdate }}</p>
                                <div class="result-info">
                                    <div class="result-info-inner">
                                        <p class="result-name">
                                            {{ result.search }}
                                        </p>
                                        <button
                                            class="btn btn-primary result-button"
                                            @click="viewDetails(result[2])"
                                        >
                                            Details
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-center">no results.</div>
                </div>
                <div class="pagination-container text-center mt-4">
                    <button
                        class="btn btn-primary"
                        @click="prevPage"
                        :disabled="currentPage === 1"
                    >
                        Previous
                    </button>
                    <span class="mx-2">{{ currentPage }}</span>
                    <button
                        class="btn Dbtn-primary"
                        @click="nextPage"
                        :disabled="currentPage === totalPages"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
        <!-- Modal para ver detalles -->
        <b-modal
            v-model="showModal"
            title="Details"
            @hide="clearModal"
            size="lg"
        >
            <b-tabs content-class="mx-2">
                <b-tab title="Search">
                    <div v-html="selectedResult"></div>
                </b-tab>
            </b-tabs>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            fields: { text: "name", value: "code" },
            states: [],
            filters: {
                search: "",
            },
            searchResults: "", // Array para almacenar los resultados de la búsqueda SERP
            newDependency: "", // Nuevo campo para la dependencia a agregar
            dependencies: [], // Lista de dependencias agregadas
            history: [],
            datesearch: new Date().toISOString().slice(0, 10),
            ipserver: null,
            showModal: false,
            selectedResult: [], // Almacena el resultado seleccionado para el modal
            currentPage: 1, // Página actual
            itemsPerPage: 7, // Registros por página
        };
    },
    methods: {
        async load() {
            try {
                const response = await axios.get(
                    `${this.$root.serverUrl}/admin/yahooSearch/history`
                );
                console.log("Datos recibidos de la consulta:", response.data);
                this.searchResults = [];
                this.searchResults = response.data;
                console.log("searchResults actualizado:", this.searchResults);
                console.log("history actualizado:", this.history);
            } catch (error) {
                console.error("Error en la búsqueda:", error);
            }
        },
        async view() {
            try {
                const response = await axios.get(
                    `${this.$root.serverUrl}/admin/yahooSearch/list`
                );
                console.log("Datos recibidos de la consulta:", response.data);
                this.selectedResult = [];
                this.selectedResult = response.data;

                console.log("searchResults actualizado:", this.selectedResult);
            } catch (error) {
                console.error("Error en la búsqueda:", error);
            }
        },

        extractValues(htmlString) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, "text/html");

            const mainLink = doc?.querySelector(".compTitle a");
            const mainLinkText =
                mainLink?.querySelector(".title span")?.textContent;
            const mainLinkUrl = mainLink?.href;

            const promotionText = doc
                .querySelector(".compText p")
                .textContent.trim();

            const additionalPromotions = [];
            const promotionLinks = doc.querySelectorAll(".compList a");
            promotionLinks.forEach((link) => {
                additionalPromotions.push({
                    text: link.textContent.trim(),
                    url: link.href,
                });
            });

            return {
                mainLinkText,
                mainLinkUrl,
                promotionText,
                additionalPromotions,
            };
        },
        extractNextLink(htmlString) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, "text/html");
            return doc.querySelector(".next")?.href;
        },
        extractRelatedSearches(htmlString) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, "text/html");
            const rows = doc.querySelectorAll(".compTable tr");
            const results = [];

            rows.forEach((row) => {
                const cells = row.querySelectorAll("td");
                cells.forEach((cell) => {
                    const link = cell.querySelector("a");
                    if (link) {
                        const text = link.innerText.trim();
                        const href = link.getAttribute("href");
                        results.push({ text, href });
                    }
                });
            });

            return results;
        },
        processAdResults(listResults) {
            return listResults.map((domElement) =>
                this.extractValues(domElement)
            );
        },
        extractSearchResults(htmlString) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, "text/html");

            const titleElement = doc.querySelector(".compTitle a");
            const descriptionElement = doc.querySelector(".compText p");
            const linkElements = doc.querySelectorAll(".compDlink a");

            const title = titleElement.innerText.trim();
            const description = descriptionElement.innerText.trim();
            const mainLink = titleElement.getAttribute("href");

            const additionalLinks = Array.from(linkElements).map((link) => ({
                text: link.innerText.trim(),
                url: link.getAttribute("href"),
            }));

            return {
                title,
                description,
                mainLink,
                additionalLinks,
            };
        },

        processSearchResults(listResults) {
            return listResults.map((domElement) =>
                this.extractSearchResults(domElement)
            );
        },
        extractVideo(htmlString) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, "text/html");

            const linkElement = doc.querySelector("a.s-card-wrapAnchor");
            const imageElement = doc.querySelector("img.s-img");
            const titleElement = doc.querySelector(
                "div.s-card-bot > p.s-card-txt.s-card-hl"
            );
            const dateElement = doc.querySelector(
                "p.s-card-txt.d-ib.s-card-date"
            );
            const viewsElement = doc.querySelector(
                "p.s-card-txt.d-ib.s-card-views"
            );
            const sourceElement = doc.querySelector("p.s-card-txt.s-card-cite");
            const timestampElement = doc.querySelector("div.ctimestamp");

            return {
                url: linkElement ? linkElement.href : null,
                imageUrl: imageElement ? imageElement.src : null,
                title: titleElement ? titleElement.innerText : null,
                date: dateElement ? dateElement.innerText : null,
                views: viewsElement ? viewsElement.innerText : null,
                source: sourceElement ? sourceElement.innerText : null,
                timestamp: timestampElement ? timestampElement.innerText : null,
            };
        },
        processVideos(listResults) {
            return listResults.map((domElement) =>
                this.extractVideo(domElement)
            );
        },
        async filter() {
            let query = this.filters.search.trim();
            if (this.newDependency.length > 0) {
                query = `${this.newDependency}`;
            }
            if (query) {
                const url = "/api/v1/realtime/web";

                const username = process.env.VUE_APP_AUTH_USER_NIMBLE;
                const password = process.env.VUE_APP_AUTH_PASSWD_NIMBLE;

                const headers = {
                    Authorization: `Basic ${Buffer.from(
                        `${username}:${password}`
                    ).toString("base64")}`,
                    "Content-Type": "application/json",
                };

                let allAdResults = [];
                let allRelatedSearches = [];
                let allSearchResults = [];
                let allVideos = [];

                let currentQuery = query;
                let attempts = 0;
                let iterations = 0;

                while (iterations < 10 && attempts < 20) {
                    const data = {
                        url:
                            "HTTPS://search.yahoo.com/search?p=" +
                            currentQuery +
                            "&fr=yfp-t&fr2=p%3Afp%2Cm%3Asb&ei=UTF-8&fp=1",
                        method: "GET",
                        parse: true,
                        format: "json",
                        render: true,
                        parser: {
                            adResult: {
                                extractor: "html",
                                type: "list",
                                selectors: [".layoutMiddle"],
                            },
                            searchResults1: {
                                extractor: "html",
                                type: "list",
                                selectors: [".dd.fst.algo.algo-sr.relsrch.Sr"],
                            },
                            searchResults2: {
                                extractor: "html",
                                type: "list",
                                selectors: [".dd.lst.algo.algo-sr.relsrch.Sr"],
                            },
                            videos: {
                                extractor: "html",
                                type: "list",
                                selectors: [".s-card-wrap.bdr-8"],
                            },
                            relatedSearches: {
                                extractor: "html",
                                selectors: [".dd.AlsoTry"],
                            },
                            pagination: {
                                extractor: "html",
                                selectors: [".compPagination"],
                            },
                        },
                    };

                    try {
                        const response = await axios.post(url, data, {
                            headers,
                        });
                        if (response.status === 200 && response.data) {
                            if (response.data.redirects) {
                                console.log("Redirect detected, retrying...");
                                attempts++;
                                continue;
                            }

                            const {
                                adResult,
                                pagination,
                                relatedSearches,
                                searchResults1,
                                searchResults2,
                                videos,
                            } = response.data.parsing;

                            allAdResults = allAdResults.concat(
                                this.processAdResults(adResult)
                            );
                            const nextLink = this.extractNextLink(pagination);
                            allRelatedSearches = allRelatedSearches.concat(
                                this.extractRelatedSearches(relatedSearches)
                            );
                            const searchResult = [
                                ...searchResults1,
                                ...searchResults2,
                            ];
                            allSearchResults = allSearchResults.concat(
                                this.processSearchResults(searchResult)
                            );
                            allVideos = allVideos.concat(
                                this.processVideos(videos)
                            );

                            if (nextLink) {
                                currentQuery = nextLink;
                            } else {
                                break;
                            }

                            iterations++;
                        }
                    } catch (error) {
                        console.error(
                            "Error en la búsqueda o inserción:",
                            error
                        );
                        break;
                    }
                }

                console.log("allAdResults:", allAdResults);
                console.log("allRelatedSearches:", allRelatedSearches);
                console.log("allSearchResults:", allSearchResults);
                console.log("allVideos:", allVideos);
            } else {
                alert("Please, input search criteria");
            }
        },

        async viewDetails(id) {
            try {
                const response = await axios.get(
                    `${this.$root.serverUrl}/admin/yahooSearch/details`,
                    {
                        params: { id },
                    }
                );
                const html = response.data;
                console.log("testeando las rutas " + html);
                this.selectedResult = html;
                console.log("selectedResult actualizado:", this.selectedResult);

                this.showModal = true;
            } catch (error) {
                console.error("Error al obtener los detalles:", error);
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },

        clearModal() {
            this.selectedResult = [];
        },
    },
    computed: {
        paginatedResults() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.searchResults.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.searchResults.length / this.itemsPerPage);
        },
    },
    created() {
        this.load();
    },
};
</script>

<style scoped>
/* Estilos CSS específicos para este componente */

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.text-center {
    text-align: center;
}

.search-container {
    background-color: white;
    padding: 2%;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.results-container {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.search-results {
    display: block;
}

.result-item {
    margin-bottom: 20px;
}

.result-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px;
}

.result-date {
    font-size: 15px;
    margin-right: 20px;
}

.result-info {
    flex-basis: 70%;
}

.result-info-inner {
    display: flex;
    align-items: center;
}

.result-name {
    font-size: 15px;
    margin-right: 20px;
}

.result-button {
    margin-left: auto;
}

.thumbnail-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Espacio entre las imágenes */
    justify-content: center; /* Centrar los thumbnails horizontalmente */
}

.thumbnail-item {
    flex: 1 1 200px; /* Permite que cada item ocupe al menos 200px y se expanda según sea necesario */
    max-width: 200px;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    overflow: hidden;
}

.thumbnail-image {
    width: 100%;
    height: auto;
    display: block;
}

.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
